<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the following reaction and its equilibrium constant:</p>

      <p class="mb-5 pl-10">
        <stemble-latex
          content="$\ce{2 NOCl(g) <=> 2 NO(g) + Cl2(g)} \qquad \text{K}_\text{c}\text{ = 0.00986}$"
        />
      </p>

      <p class="mb-3">
        The concentrations are found to be
        <stemble-latex content="$\ce{[NOCl]}\text{ = 0.250 M,}$" />
        <stemble-latex content="$\ce{[NO]}\text{ =}$" />
        <number-value :value="concNO" unit="\text{M,}" /> and
        <stemble-latex content="$\ce{[Cl2]}\text{ = 0.325 M:}$" />
      </p>

      <p class="mb-4">
        a) Calculate the reaction quotient,
        <stemble-latex content="$\text{Q}_\text{c}.$" />
      </p>

      <calculation-input
        v-model="inputs.Qrxn"
        class="mb-5"
        prepend-text="$\text{Q}_\text{c}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-1">
        b) Which direction will the reaction proceed in order to reach equilibrium?
      </p>

      <v-radio-group v-model="inputs.rxnDirection" class="mt-0 mb-n5" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="pl-8 mb-1 mt-0"
          :value="option.value"
        >
          <template #label>
            <stemble-latex style="font-size: 14px" :content="`$\\text{${option.expression}}$`" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question371',
  components: {
    StembleLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Qrxn: null,
        rxnDirection: null,
      },
      options1: [
        {
          expression: 'Forward - towards the products',
          value: 'forward',
        },
        {
          expression: 'Backward - towards the reactants',
          value: 'backward',
        },
        {
          expression: 'The reaction is already at equilibrium',
          value: 'neither',
        },
      ],
    };
  },
  computed: {
    concNO() {
      return this.taskState.getValueBySymbol('concNO').content;
    },
  },
};
</script>
